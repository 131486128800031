/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../hooks/AuthProvider";
import { DropDownIcon, NoImageIcon, SpinnerIcon } from "../../icons";
import MenuItem from "./MenuItem";
import routes from "./sidebarConfigs";
import SidebarSubmenu from "./SidebarSubmenu";
import { states } from "../../../constants";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { notifier } from "utils/notifier";
import { checkUserPermissions } from "utils/checkUserPermissions";

interface Company {
  id: string;
  name: string;
}

const SidebarContent = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const {
    user: { employee },
    userPages,
  } = useAuth();

  const { listCompanies, createLogin, viewCompanies } = useSelector(
    (state: RootStateOrAny) => state
  );

  useEffect(() => {
    if (employee.user?.email) {
      dispatch({
        type: states.listCompanies.constants.request,
        payload: { email: employee.user?.email },
      });
    }
  }, [employee.user?.email]);

  useEffect(() => {
    if (employee?.companyId) {
      dispatch({
        type: states.viewCompanies.constants.request,
        params: { id: employee?.companyId },
      });
    }
  }, [employee?.companyId]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleDropdown();
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogin = (companyId: string) => {
    if (employee.companyId === companyId) {
      return;
    }
    toggleDropdown();
    dispatch({
      type: states.createLogin.constants.request,
      payload: { token: localStorage.getItem("userToken") || "" },
      params: { companyId },
    });
  };

  const checkLoginInfo = async (info: any) => {
    const { errorMessage, isAuthenticated } = await checkUserPermissions(
      info.employee.id
    );
    if (isAuthenticated) {
      notifier.success("Welcome");
      window.location.replace("/app/dashboard");
    }
    if (errorMessage) {
      notifier.error(errorMessage);
    }
  };

  useEffect(() => {
    if (createLogin.success && createLogin.data) {
      checkLoginInfo(createLogin.data);
    }
    if (createLogin?.errors && createLogin?.errors.length) {
      notifier.error(createLogin?.errors[0]?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLogin]);
  const allowedPages = routes.filter((route) => userPages.includes(route.path));
  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <div className="flex px-1 py-1 flex-row items-center gap-4 w-full">
        <div className="h-12 w-12 grid place-items-center rounded-md border-2 dark:border-[1px]  dark:border-gray-500 ">
          {viewCompanies?.loading ? (
            <SpinnerIcon />
          ) : viewCompanies?.data?.logoUrl[0] ? (
            <img
              className="w-30 h-30 rounded"
              src={viewCompanies?.data?.logoUrl[0]}
              alt="Large avatar"
            />
          ) : (
            <NoImageIcon />
          )}
        </div>
        <div className="flex flex-col gap-2 relative">
          <button
            id="dropdownBottomButton"
            data-dropdown-toggle="dropdownBottom"
            data-dropdown-placement="bottom"
            className="text-black  bold  dark:text-white focus:ring-4 font-medium text-sm px-1 inline-flex items-center !border-0 w-44 hover:border-0"
            type="button"
            onClick={handleButtonClick}>
            {viewCompanies?.data?.name?.toUpperCase()}
            <DropDownIcon className="w-2.5 h-2.5 ml-2.5" />
          </button>
          {isDropdownOpen && (
            <div
              ref={dropdownRef}
              id="dropdownBottom"
              className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute top-7 right-0">
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownBottomButton">
                {listCompanies?.data?.exist?.company?.map(
                  (company: Company) => (
                    <li key={company.id}>
                      <a
                        onClick={() => handleLogin(company.id)}
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        {company.name}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      <ul className="mt-6">
        {allowedPages.map((route, routeKey) =>
          route.routes ? (
            <SidebarSubmenu route={route} key={route.name} />
          ) : (
            <MenuItem
              key={routeKey}
              name={route.name}
              path={"/app/" + route.path}
              icon={route.icon}
            />
          )
        )}
      </ul>
    </div>
  );
};

export default SidebarContent;
